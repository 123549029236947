import { defineStore } from 'pinia';
import { useGtm } from '@gtm-support/vue-gtm';

export const useModalStore = defineStore('modal-store', {
    state: () => {
        return {
            activeModals: {},
            data: {}
        };
    },

    actions: {
        close(payload) {
            if (typeof payload === 'string') {
                return this.deactivateModal(payload);
            }

            if (payload.name) {
                this.deactivateModal(payload.name);
            }
        },

        open(payload) {
            // If no modal name is present, reset it.
            if (!payload || !payload.name) {
                this.deactivateAllModals();
            }

            // If the payload is a string, just interpret that as the modal's name.
            if (typeof payload === 'string') {
                return this.activateModal({
                    modalName: payload
                });
            }

            // If it does have a name, open it. And set the payload object
            // to the current modal data, for use in the component iself.
            if (payload.name) {
                this.activateModal({
                    modalName: payload.name,
                    data: payload.data
                });
            }
        },

        activateModal(payload) {
            this.activeModals[payload.modalName] = true;

            if (payload.data) {
                this.data[payload.modalName] = payload.data;
            }

            document.body.setAttribute(`data-modal-${payload.modalName}-open`, 'true');
            document.body.setAttribute('data-prevent-scrolling', 'true');

            useGtm()?.trackEvent({
                event: 'open_modal',
                modal: payload.modalName
            });
        },

        deactivateModal(modalName) {
            this.activeModals[modalName] = false;
            this.data[modalName] = null;

            document.body.setAttribute(`data-modal-${modalName}-open`, 'false');

            const openModals = Object.values(this.activeModals).filter(value => value);
            if (openModals.length === 0) {
                document.body.removeAttribute('data-prevent-scrolling');
            }
        },

        deactivateAllModals() {
            this.activeModals = {};
        }
    }
});
